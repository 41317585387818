import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Gritbros - Inso. Your Business. Our Solutions</title>
                <meta name="description" content="Gritbros - Inso. Your Business. Our Solutions" />
                <meta name="og:title" property="og:title" content="Gritbros - Inso. Your Business. Our Solutions"></meta>
                <meta name="og:description" property="og:description" content="Are you ready to get started on your next revolutional project? Connect with us now!"></meta>
                <meta name="twitter:card" content="Gritbros - Inso. Your Business. Our Solutions"></meta>
                <link rel="canonical" href="https://gritbros.com/"></link>
                <meta property="og:image" content="https://gritbros.com/static/og-image.png" />
            </Helmet>
        </div>
    )
}

export default SEO
